var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h1", [_vm._v("其他接口")]),
      _c("h3", [_vm._v("接口地址说明")]),
      _c("p", [
        _c("strong", [
          _vm._v(
            "接口地址定义：在这里以api_url作为接口地址前缀变量名，api_url将和接口路径(path)组成完整的接口地址。以下为目前版本的相关api_url信息："
          ),
        ]),
      ]),
      _c("ul", [
        _c("li", [_c("strong", [_vm._v("正式机api_url：http://x.x.x.x/api")])]),
        _c("li", [_c("strong", [_vm._v("正式机api_url：待定")])]),
      ]),
      _c("h2", [_vm._v("接口说明")]),
      _c("h3", [_vm._v("车辆列表")]),
      _c("p", [_vm._v("说明：获取车辆列表信息。")]),
      _c("h4", [_c("strong", [_vm._v("接口路径")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("接口地址")])]),
            _c("th", [_c("strong", [_vm._v("{api_url}/vehicleList")])]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_c("strong", [_vm._v("请求方式")])]),
            _c("td", [_c("strong", [_vm._v("POST")])]),
          ]),
        ]),
      ]),
      _c("h4", [_c("strong", [_vm._v("请求参数")])]),
      _c("p", [_c("strong", [_vm._v("header")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("字段名")])]),
            _c("th", [_c("strong", [_vm._v("变量名")])]),
            _c("th", [_c("strong", [_vm._v("类型")])]),
            _c("th", [_c("strong", [_vm._v("是否必要")])]),
            _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_c("strong", [_vm._v("授权")])]),
            _c("td", [_vm._v("Authorization")]),
            _c("td", [_c("strong", [_vm._v("string")])]),
            _c("td", [_c("strong", [_vm._v("是")])]),
            _c("td", [_c("strong", [_vm._v("max")])]),
          ]),
        ]),
      ]),
      _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("字段名")])]),
            _c("th", [_c("strong", [_vm._v("变量名")])]),
            _c("th", [_c("strong", [_vm._v("类型")])]),
            _c("th", [_c("strong", [_vm._v("是否必要")])]),
            _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [_c("td"), _c("td"), _c("td"), _c("td"), _c("td")]),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("示例：")])]),
      _c("pre", { pre: true }, [
        _c("code", { pre: true, attrs: { "v-pre": "" } }, [_vm._v("无\n")]),
      ]),
      _c("h4", [_c("strong", [_vm._v("回应数据")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("字段名")])]),
            _c("th", [_c("strong", [_vm._v("变量名")])]),
            _c("th", [_c("strong", [_vm._v("类型")])]),
            _c("th", [_c("strong", [_vm._v("举例")])]),
            _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("车辆编号")]),
            _c("td", [_vm._v("vehicleId")]),
            _c("td", [_vm._v("string")]),
            _c("td", [_vm._v("T0001")]),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("车牌号")]),
            _c("td", [_vm._v("licensePlateNum")]),
            _c("td", [_vm._v("string")]),
            _c("td", [_vm._v("00001")]),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("车辆名称")]),
            _c("td", [_vm._v("name")]),
            _c("td", [_vm._v("stirng")]),
            _c("td", [_vm._v("可俐1号")]),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("所属机构")]),
            _c("td", [_vm._v("deptName")]),
            _c("td", [_vm._v("string")]),
            _c("td", [_vm._v("于万科技")]),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("车辆类别")]),
            _c("td", [_vm._v("vehicleType")]),
            _c("td", [_vm._v("string")]),
            _c("td", [_vm._v("自动扫地车")]),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("是否自动驾驶")]),
            _c("td", [_vm._v("automatic")]),
            _c("td", [_vm._v("int")]),
            _c("td", [_vm._v("1")]),
            _c("td", [_vm._v("1是，2否")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("车辆摄像头")]),
            _c("td", [_vm._v("camera")]),
            _c("td", [_vm._v("string")]),
            _c("td"),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("车辆状态")]),
            _c("td", [_vm._v("status")]),
            _c("td", [_vm._v("int")]),
            _c("td", [_vm._v("1")]),
            _c("td", [_vm._v("1正常，0下架")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("行驶时长(自动驾驶)")]),
            _c("td", [_vm._v("automaticDrivingTime")]),
            _c("td", [_vm._v("int")]),
            _c("td"),
            _c("td", [_vm._v("单位：秒")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("行驶时长(人工驾驶)")]),
            _c("td", [_vm._v("artificialDrivingTime")]),
            _c("td", [_vm._v("int")]),
            _c("td"),
            _c("td", [_vm._v("单位：秒")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("行驶总距离(自动驾驶)")]),
            _c("td", [_vm._v("automaticTotalDistance")]),
            _c("td", [_vm._v("int")]),
            _c("td"),
            _c("td", [_vm._v("单位：米")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("行驶总距离(人工驾驶)")]),
            _c("td", [_vm._v("artificialTotalDistance")]),
            _c("td", [_vm._v("int")]),
            _c("td"),
            _c("td", [_vm._v("单位：米")]),
          ]),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("示例：")])]),
      _c("pre", { pre: true }, [
        _c(
          "code",
          { pre: true, attrs: { "v-pre": "", class: "language-json" } },
          [
            _vm._v("{  \n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"code"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("200"),
            ]),
            _vm._v(",\n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"msg"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"查询成功"'),
            ]),
            _vm._v(",\n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"total"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("2"),
            ]),
            _vm._v(",\n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"data"'),
            ]),
            _vm._v(":{\n    [{\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"vehicleId"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"T0001"'),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"licensePlateNum"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"00001"'),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"name"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"可俐1号"'),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"deptName"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"于万科技"'),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"vehicleType"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"自动扫地车"'),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"automatic"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("1"),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"camera"'),
            ]),
            _vm._v(": [{"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"name"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"驾驶舱"'),
            ]),
            _vm._v(", "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"addr"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"rtsp://demo.yw.stream"'),
            ]),
            _vm._v(", "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"main"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("1"),
            ]),
            _vm._v("},{"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"name"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"驾驶舱"'),
            ]),
            _vm._v(", "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"addr"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"rtsp://demo.yw.stream"'),
            ]),
            _vm._v(", "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"main"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("0"),
            ]),
            _vm._v("}],\n      \t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"automaticDrivingTime"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("9000"),
            ]),
            _vm._v(",\n  \t\t\t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"artificialDrivingTime"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("1000"),
            ]),
            _vm._v(",\n  \t\t\t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"automaticTotalDistance"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("999"),
            ]),
            _vm._v(",\n  \t\t\t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"artificialTotalDistance"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("888"),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"status"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("1"),
            ]),
            _vm._v("\n    },\n    {\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"vehicleId"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"T0002"'),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"licensePlateNum"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"00002"'),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"name"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"可俐2号"'),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"deptName"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"于万科技"'),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"vehicleType"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"自动扫地车"'),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"automatic"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("1"),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"camera"'),
            ]),
            _vm._v(": [{"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"name"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"驾驶舱"'),
            ]),
            _vm._v(", "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"addr"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"rtsp://demo.yw.stream"'),
            ]),
            _vm._v(", "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"main"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("1"),
            ]),
            _vm._v("},{"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"name"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"驾驶舱"'),
            ]),
            _vm._v(", "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"addr"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"rtsp://demo.yw.stream"'),
            ]),
            _vm._v(", "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"main"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("0"),
            ]),
            _vm._v("}],\n      \t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"automaticDrivingTime"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("9000"),
            ]),
            _vm._v(",\n  \t\t\t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"artificialDrivingTime"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("1000"),
            ]),
            _vm._v(",\n  \t\t\t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"automaticTotalDistance"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("999"),
            ]),
            _vm._v(",\n  \t\t\t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"artificialTotalDistance"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("888"),
            ]),
            _vm._v(",\n        "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"status"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("1"),
            ]),
            _vm._v("\n    }]\n\t}\n}\n"),
          ]
        ),
      ]),
      _c("h3", [_vm._v("车辆状态")]),
      _c("p", [_vm._v("说明：获取车辆状态信息。")]),
      _c("h4", [_c("strong", [_vm._v("接口路径")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("接口地址")])]),
            _c("th", [_vm._v("**{api_url}/vehicleStatus")]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_c("strong", [_vm._v("请求方式")])]),
            _c("td", [_c("strong", [_vm._v("POST")])]),
          ]),
        ]),
      ]),
      _c("h4", [_c("strong", [_vm._v("请求参数")])]),
      _c("p", [_c("strong", [_vm._v("header")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("字段名")])]),
            _c("th", [_c("strong", [_vm._v("变量名")])]),
            _c("th", [_c("strong", [_vm._v("类型")])]),
            _c("th", [_c("strong", [_vm._v("是否必要")])]),
            _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_c("strong", [_vm._v("授权")])]),
            _c("td", [_vm._v("Authorization")]),
            _c("td", [_c("strong", [_vm._v("string")])]),
            _c("td", [_c("strong", [_vm._v("是")])]),
            _c("td", [_c("strong", [_vm._v("max")])]),
          ]),
        ]),
      ]),
      _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("字段名")])]),
            _c("th", [_c("strong", [_vm._v("变量名")])]),
            _c("th", [_c("strong", [_vm._v("类型")])]),
            _c("th", [_c("strong", [_vm._v("是否必要")])]),
            _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_c("strong", [_vm._v("车辆编码")])]),
            _c("td", [_c("strong", [_vm._v("vehicleId")])]),
            _c("td", [_c("strong", [_vm._v("string")])]),
            _c("td", [_c("strong", [_vm._v("是")])]),
            _c("td", [_c("strong", [_vm._v("max")])]),
          ]),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("示例：")])]),
      _c("pre", { pre: true }, [
        _c(
          "code",
          { pre: true, attrs: { "v-pre": "", class: "language-json" } },
          [
            _vm._v("{\n\t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"vehicleId"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"T0001"'),
            ]),
            _vm._v("\n}\n"),
          ]
        ),
      ]),
      _c("h4", [_c("strong", [_vm._v("回应数据")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("字段名")])]),
            _c("th", [_c("strong", [_vm._v("变量名")])]),
            _c("th", [_c("strong", [_vm._v("类型")])]),
            _c("th", [_c("strong", [_vm._v("举例")])]),
            _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("车辆编号")]),
            _c("td", [_vm._v("vehicleId")]),
            _c("td", [_vm._v("string")]),
            _c("td", [_vm._v("T0001")]),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("在线状态")]),
            _c("td", [_vm._v("online")]),
            _c("td", [_vm._v("Boolean")]),
            _c("td", [_vm._v("True")]),
            _c("td", [_vm._v("true在线，false离线")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("作业状态")]),
            _c("td", [_vm._v("workStatus")]),
            _c("td", [_vm._v("int")]),
            _c("td", [_vm._v("1")]),
            _c("td", [_vm._v("1正在作业，2暂停作业，3停止作业，4空闲")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("电量")]),
            _c("td", [_vm._v("soc")]),
            _c("td", [_vm._v("Double")]),
            _c("td", [_vm._v("50")]),
            _c("td", [_vm._v("50:剩余50%")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("总里程")]),
            _c("td", [_vm._v("odo")]),
            _c("td", [_vm._v("int")]),
            _c("td", [_vm._v("1234")]),
            _c("td", [_vm._v("1234公里")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("作业里程")]),
            _c("td", [_vm._v("workOdo")]),
            _c("td", [_vm._v("int")]),
            _c("td", [_vm._v("100")]),
            _c("td", [_vm._v("100公里")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("空驶里程")]),
            _c("td", [_vm._v("emptyOdo")]),
            _c("td", [_vm._v("int")]),
            _c("td", [_vm._v("99")]),
            _c("td", [_vm._v("99公里")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("自动里程")]),
            _c("td", [_vm._v("autoOdo")]),
            _c("td", [_vm._v("int")]),
            _c("td", [_vm._v("200")]),
            _c("td", [_vm._v("公里")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("遥控里程")]),
            _c("td", [_vm._v("controlOdo")]),
            _c("td", [_vm._v("int")]),
            _c("td", [_vm._v("10")]),
            _c("td", [_vm._v("公里")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("速度")]),
            _c("td", [_vm._v("speed")]),
            _c("td", [_vm._v("double")]),
            _c("td", [_vm._v("5.5")]),
            _c("td", [_vm._v("5.5米/秒")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("最近一次点位上报时间")]),
            _c("td", [_vm._v("gpsTime")]),
            _c("td", [_vm._v("datetime")]),
            _c("td", [_vm._v("2021-07-3-3 14:33:21")]),
            _c("td"),
          ]),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("示例：")])]),
      _c("pre", { pre: true }, [
        _c(
          "code",
          { pre: true, attrs: { "v-pre": "", class: "language-json" } },
          [
            _vm._v("{\n  \t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"code"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("200"),
            ]),
            _vm._v(",\n  \t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"msg"'),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"查询成功"'),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"vehicleId"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"T0001"'),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"online"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
              _vm._v("true"),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"workStatus"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("1"),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"soc"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("50"),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"odo"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("1234"),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"emptyOdo"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("99"),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"autoOdo"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("200"),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"controlOdo"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("10"),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"speed"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("5.5"),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"gpsTime"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("2021"),
            ]),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("-07"),
            ]),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("-3"),
            ]),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("-3"),
            ]),
            _vm._v(" "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("14"),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("33"),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("21"),
            ]),
            _vm._v("\n}\n"),
          ]
        ),
      ]),
      _c("h3", [_vm._v("下发任务")]),
      _c("p", [_vm._v("说明：下发任务。")]),
      _c("h4", [_c("strong", [_vm._v("接口路径")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("接口地址")])]),
            _c("th", [_vm._v("**{api_url}/excTask")]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_c("strong", [_vm._v("请求方式")])]),
            _c("td", [_c("strong", [_vm._v("POST")])]),
          ]),
        ]),
      ]),
      _c("h4", [_c("strong", [_vm._v("请求参数")])]),
      _c("p", [_c("strong", [_vm._v("header")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("字段名")])]),
            _c("th", [_c("strong", [_vm._v("变量名")])]),
            _c("th", [_c("strong", [_vm._v("类型")])]),
            _c("th", [_c("strong", [_vm._v("是否必要")])]),
            _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_c("strong", [_vm._v("授权")])]),
            _c("td", [_vm._v("Authorization")]),
            _c("td", [_c("strong", [_vm._v("string")])]),
            _c("td", [_c("strong", [_vm._v("是")])]),
            _c("td", [_c("strong", [_vm._v("max")])]),
          ]),
        ]),
      ]),
      _c("h4", [_c("strong", [_vm._v("body(application/json)")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("字段名")])]),
            _c("th", [_c("strong", [_vm._v("变量名")])]),
            _c("th", [_c("strong", [_vm._v("类型")])]),
            _c("th", [_c("strong", [_vm._v("是否必要")])]),
            _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_c("strong", [_vm._v("车辆编码")])]),
            _c("td", [_c("strong", [_vm._v("vehicleId")])]),
            _c("td", [_c("strong", [_vm._v("string")])]),
            _c("td", [_c("strong", [_vm._v("是")])]),
            _c("td", [_c("strong", [_vm._v("max")])]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("任务名称")]),
            _c("td", [_vm._v("taskName")]),
            _c("td", [_vm._v("string")]),
            _c("td", [_vm._v("是")]),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("开始时间")]),
            _c("td", [_vm._v("taskStartTime")]),
            _c("td", [_vm._v("datetime")]),
            _c("td", [_vm._v("是")]),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("预计结果时间")]),
            _c("td", [_vm._v("taskExpectTime")]),
            _c("td", [_vm._v("datetime")]),
            _c("td"),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("路线名称")]),
            _c("td", [_vm._v("routeName")]),
            _c("td", [_vm._v("string")]),
            _c("td", [_vm._v("是")]),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("任务备注")]),
            _c("td", [_vm._v("remark")]),
            _c("td", [_vm._v("string")]),
            _c("td"),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("任务冲突")]),
            _c("td", [_vm._v("taskConflict")]),
            _c("td", [_vm._v("string")]),
            _c("td"),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("任务进度")]),
            _c("td", [_vm._v("taskProgress")]),
            _c("td", [_vm._v("string")]),
            _c("td"),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", [_vm._v("发起人")]),
            _c("td", [_vm._v("createBy")]),
            _c("td", [_vm._v("string")]),
            _c("td"),
            _c("td"),
          ]),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("示例：")])]),
      _c("pre", { pre: true }, [
        _c(
          "code",
          { pre: true, attrs: { "v-pre": "", class: "language-json" } },
          [
            _vm._v("{\n\t"),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"vehicleId"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"T0001"'),
            ]),
            _vm._v(",\n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"taskStartTime"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("2021"),
            ]),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("-4"),
            ]),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("-8"),
            ]),
            _vm._v(" "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("11"),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("10"),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("10"),
            ]),
            _vm._v(",\n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"taskExpectTime"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("2021"),
            ]),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("-4"),
            ]),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("-8"),
            ]),
            _vm._v(" "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("12"),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("10"),
            ]),
            _vm._v(":"),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("10"),
            ]),
            _vm._v(",\n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"routeName"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"南京路XX段"'),
            ]),
            _vm._v(",\n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"remark"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('""'),
            ]),
            _vm._v(",\n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"taskConflict"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('""'),
            ]),
            _vm._v(",\n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"taskProgress"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('""'),
            ]),
            _vm._v(",\n  "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"createBy"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('""'),
            ]),
            _vm._v("\n}\n"),
          ]
        ),
      ]),
      _c("h4", [_c("strong", [_vm._v("回应数据")])]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("strong", [_vm._v("字段名")])]),
            _c("th", [_c("strong", [_vm._v("变量名")])]),
            _c("th", [_c("strong", [_vm._v("类型")])]),
            _c("th", [_c("strong", [_vm._v("举例")])]),
            _c("th", [_c("strong", [_vm._v("（范围）说明")])]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("信息")]),
            _c("td", [_vm._v("msg")]),
            _c("td", [_vm._v("string")]),
            _c("td"),
            _c("td", [_vm._v("下发成功，下发失败，下发异常")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("代码")]),
            _c("td", [_vm._v("code")]),
            _c("td", [_vm._v("int")]),
            _c("td", [_vm._v("0")]),
            _c("td", [_vm._v("0:成功，1:失败，2:异常")]),
          ]),
        ]),
      ]),
      _c("p", [_c("strong", [_vm._v("示例：")])]),
      _c("pre", { pre: true }, [
        _c(
          "code",
          { pre: true, attrs: { "v-pre": "", class: "language-json" } },
          [
            _vm._v("{\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"msg"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
              _vm._v('"下发成功"'),
            ]),
            _vm._v(",\n    "),
            _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
              _vm._v('"code"'),
            ]),
            _vm._v(": "),
            _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
              _vm._v("0"),
            ]),
            _vm._v("\n}\n"),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }